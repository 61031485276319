<template>
  <div>
    <div>
      <b-card-actions
        title="Filters"
        action-collapse
        collapsed
      >
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Agent"
              label-for="v-name"
            >
              <b-form-input
                id="v-name"
                v-model="filters['name']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Ref No"
              label-for="v-ref-no"
            >
              <b-form-input
                id="v-ref-no"
                v-model="filters['ref_no']"
                placeholder="Search"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Apply and Clear Filters -->
        <b-row class="mt-1">
          <b-col>
            <filter-buttons
              :filter-data="filters"
              :apply-filters="applyFilters"
              :clear-filters="clearFilters"
            />
          </b-col>
        </b-row>
      </b-card-actions>
    </div>
    <div>
      <b-card
        no-body
      >
        <div
          class="m-2"
        >
          <b-row>
            <b-col
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mt-1 mb-md-0"
            >
              <b-button
                v-can="'agency-create'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="custom-button-color"
                @click="onClickCreate"
              >
                <feather-icon icon="PlusIcon" />
                Add New
              </b-button>
            </b-col>
            <b-col
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
            >
              <ReportTableButtons
                class="mt-2"
                :items="items"
                :json_fieldz="json_fields"
                :name="`Agency - Page ${page}.xls`"
                :bulkname="`Agency.xls`"
                :fetch="fetchAgentListNoPagination"
                @refresh="onClickRefresh"
              />
            </b-col>
          </b-row>
        </div>

        <b-overlay
          id="overlay-background"
          blur="10px"
          opacity="0.30"
          rounded="sm"
          variant="light"
        >
          <b-table
            striped
            hover
            responsive
            :current-page="page"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            show-empty
            sticky-header="100vh"
            class="hide-vertical-scroll"
          >
            <template #empty>
              <TableDataFetching
                :rows="items"
                :data-loading="dataLoading"
              />
            </template>
            <template #cell(logo_url)="data">
              <div class="d-flex align-items-center">
                <b-avatar
                  size="32"
                  variant="light-primary"
                  :src="data.value"
                />
                <div>
                  <div class="font-weight-bolder ml-2">
                    {{ data.item.name }}
                  </div>
                  <div class="font-small-2 ml-2">
                    {{ data.item.ref_no }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(primary_contact)="data">
              {{ data.value || '---' }}
            </template>
            <template #cell(rate_card)="data">
              <div class="d-flex align-items-center">
                <div class="font-weight-bolder ml-1">
                  <router-link
                    v-if="data.item.rate_card_id != null"
                    :to="{ name: 'view-rate-card', params: { rateCardType: 'agent', id: data.item.rate_card_id }}"
                  >
                    {{ data.item.rate_card === null ? 'N/A' : data.item.rate_card.card_name }}
                  </router-link>
                  <router-link
                    v-else
                    to=""
                  >
                    N/A
                  </router-link>
                </div>
              </div>
            </template>
            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="12"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-can="'agency-show'"
                  @click="onClickUpdate(data.item.id)"
                >
                  <feather-icon icon="ToolIcon" />
                  Manage Agency
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-overlay>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-sm-start"
            >
              <label class="width-75">Per page</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-1 width-100 mr-2"
              />
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              md="9"
              class="d-flex align-items-center justify-content-sm-end"
            >
              <span class="text-muted mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
              <b-pagination
                v-model="page"
                :total-rows="(meta.total)? meta.total : 0"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
    <set-agent ref="set_agent" />
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  VBModal,
  BOverlay,
  VBTooltip,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Filter from '@core/mixins/filter'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import SetAgent from '@/components/demarcation/SetAgentBranchModal.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const ResourceRepository = RepositoryFactory.get('resource')

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BCardActions,
    vSelect,
    BCard,
    BTable,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BPagination,
    BButton,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BOverlay,
    SetAgent,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage],
  data() {
    return {
      filters: {},
      sort: '',
      perPage: 20,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      page: 1,
      meta: {},
      sortBy: '',
      sortDesc: false,
      dataLoading: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      items: [],
      fields: [
        {
          key: 'logo_url', label: 'Agency', thClass: 'text-left', tdClass: 'text-left',
        },
        { key: 'primary_address', label: 'Address', sortable: true },
        { key: 'primary_email', label: 'Email', sortable: true },
        { key: 'primary_contact', label: 'Contact', sortable: true },
        {
          key: 'rate_card', label: 'Rate Card', thClass: 'text-center', tdClass: 'text-left',
        },
        { key: 'action', label: 'Action', sortable: false },
      ],
      json_fields: {
        Agency: {
          field: 'name',
          callback: value => `"${value}"`,
        },
        'Ref No': 'ref_no',
        Address: {
          field: 'primary_address',
          callback: value => `"${value}"`,
        },
        Email: 'primary_email',
        'Rate Card': {
          field: 'rate_card.card_name',
          callback: value => `"${value}"`,
        },
      },
      form: {},
      treeViewFields: [],
      selectedCities: [],
      selectedCitiesCache: [],
      firstExpansion: true,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchAgentList()
      },
    },
    deep: true,
    page() {
      this.fetchAgentList()
    },
    perPage() {
      this.fetchAgentList()
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.fetchAgentList()
  },
  methods: {
    async fetchAgentList() {
      this.dataLoading = true
      this.items = []
      try {
        const { data } = (await ResourceRepository.getAllAgencyList(this.page, this.filterQuery, this.perPage, this.sort))
        this.items = data.data
        this.meta = data.meta
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.dataLoading = false
    },
    async fetchAgentListNoPagination() {
      try {
        const { data } = (await ResourceRepository.getAllAgencyListNoPagination(this.filterQuery, this.sort))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    handleChangePage(page) {
      this.page = page
      this.fetchAgentList()
    },
    applyFilters() {
      this.fetchAgentList()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchAgentList()
      })
    },
    onClickRefresh() {
      this.fetchAgentList()
    },
    onClickCreate() {
      this.$refs.set_agent.openModal(null, 'add', 'Agent')
    },
    onClickUpdate(id) {
      this.$refs.set_agent.openModal(id, 'edit', 'Agent')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/required.scss';
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
